<template>
    <base-btn-google
        :loading="loading"
        @click="onClick()"
    />
</template>

<script setup>
import { defineProps, defineEmits, onUnmounted } from 'vue';
import { useHead } from '@unhead/vue';
import { useCurrentInstance } from '../composables/useCurrentInstance';
import BaseBtnGoogle from './BaseBtnGoogle.vue';

defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
});
const emit = defineEmits(['click', 'loginUser']);

const { $store } = useCurrentInstance();

const paramsApi = $store.state.params.api;

const loginWithGoogle = () => {
    window.gapi.load('auth2', async () => {
        await window.gapi.auth2.init({
            client_id: paramsApi.GOOGLE.SOCIAL_LOGIN.ID,
        });

        const authInstance = window.gapi.auth2.getAuthInstance();
        authInstance.signIn().then((gUser) => {
            const gToken = gUser.getAuthResponse().id_token;
            emit('loginUser', { gToken });
        });
    });
};

const onClick = () => {
    emit('click');
    loginWithGoogle();
};

useHead({
    script: [
        { key: 'googleJs', src: 'https://apis.google.com/js/platform.js' },
    ],
}, { mode: 'client' });

onUnmounted(() => {
    useHead({
        script: [
            { key: 'googleJs' },
        ],
    }, { mode: 'client' });
});
</script>
