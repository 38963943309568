var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.BaseBtnGoogle, {
    attrs: { loading: _vm.loading },
    on: {
      click: function ($event) {
        return _setup.onClick()
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }